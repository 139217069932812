"use client"
import { Input } from "components/controls/Input";
import { HomeAddressPicker } from "components/controls/search/HomeAddressPicker";
import { HomeEventTypePicker } from "components/controls/search/HomeEventTypePicker";
import { useState } from "react";
import { MdOutlineSearch, MdOutlinePeopleAlt, MdOutlineLocationOn } from "react-icons/md";
import qs from 'qs';
import isEmpty from "utilities/isEmpty";
import { useRouter } from "next/navigation";
import Paths from "lib/routes/Paths";
import { useAuthWallFunction } from "hooks/useAuthWallFunction";
import { alertSuccess } from "components/controls/functions/alerts";

export function HomeSearch(props) {
    const router = useRouter();
    const [eventType, setEventType] = useState(null);
    const [guests, setGuests] = useState(null);
    const [address, setAddress] = useState(null);

    const handleGuestsOnChange = (e) => {
        setGuests(!isEmpty(e.target.value) ? e.target.value : null);
    }

    const handleSearch = (e) => {
        const search = { eventType: eventType, people: guests, location: address?.id, locationSource: address?.locationSourceId };
        const queryString = qs.stringify(search, { allowEmptyArrays: true, encodeValuesOnly: true, skipNulls: true });

        if (queryString) {
            return router.push(`${Paths.SEARCH.ROOT}?${queryString}`);
        }
        return router.push(Paths.SEARCH.ROOT);
    }

    // const testFunction1 = useAuthWallFunction(() => alertSuccess("Success!"));

    return (<div className="grid grid-cols-11 bg-base-100 px-4 py-2 lg:py-4 rounded-lg shadow-md lg:space-x-3">
        <div className="col-span-12 lg:col-span-3 flex items-center border-b lg:border-b-0 lg:border-r border-base-content/20 py-3 lg:py-0 ">
            <div className="mr-3 ">
                <MdOutlineSearch className="h-8 w-8 text-base-content/50" />
            </div>
            <div className=" w-full pb-1 lg:pb-2 pr-3">
                <div className="text-sm font-medium uppercase">Event type</div>
                <div className=""> <HomeEventTypePicker
                    className="w-full"
                    onChange={(value) => setEventType(value?.value)}
                    name={"addressId"}
                    // error={errors.addressId && touched.addressId}
                    // success={!errors.addressId && touched.addressId}
                    placeholder={"What are you planning?"}
                // value={!isEmpty(values.addressId) ? {
                //     value: values.addressId, label: values.addressDescription
                // } : null}
                // isDisabled={isEmpty(values.countryId)}
                /></div>
            </div>
        </div>
        <div className="col-span-12 lg:col-span-3 flex items-center border-b lg:border-b-0 lg:border-r border-base-content/20 py-3 lg:py-0">
            <div className="mr-3 flex items-center">
                <MdOutlinePeopleAlt className="h-8 w-8 text-base-content/50" />
            </div>
            <div className=" w-full pb-1 lg:pb-2 pr-3">
                <div className="text-sm font-medium uppercase">Guests</div>
                <div> <Input
                    blank={true}
                    className={`w-full peer focus:outline-none  focus:outline-0 focus:border-0 bg-base-100 placeholder-base-content/60 text-base-content text-xl xl:text-2xl h-[32px]`}
                    placeholder="Number of guests"
                    onChange={handleGuestsOnChange}
                    value={guests}
                    // positiveOnly={true}
                    type="number"
                    step="5"
                    min="0"
                    max="5000"
                // {...rest}
                /></div>
            </div>
        </div>
        <div className="col-span-12 lg:col-span-3 flex items-center py-3 lg:py-0">
            <div className="mr-3 flex items-center">
                <MdOutlineLocationOn className="h-8 w-8 text-base-content/50" />
            </div>
            <div className=" w-full pb-1 lg:pb-2 pr-3">
                <div className="text-sm font-medium uppercase">Location</div>
                <div><HomeAddressPicker
                    className="w-full"
                    onChange={(value) => setAddress(value?.value)}
                    name={"addressId"}
                    // error={errors.addressId && touched.addressId}
                    // success={!errors.addressId && touched.addressId}
                    placeholder={"Event location"}
                // value={!isEmpty(values.addressId) ? {
                //     value: values.addressId, label: values.addressDescription
                // } : null}
                // isDisabled={isEmpty(values.countryId)}
                /></div>
            </div>
        </div>
        <div className="col-span-12 lg:col-span-2 flex items-center pb-3 lg:py-0">
            <button type="button" onClick={handleSearch} className="btn btn-block btn-lg btn-success">Search</button>
        </div>
        {/* <button
            onClick={() => {
                testFunction1();
            }}>
            Test 1
        </button> */}
    </div>);
}