import { useState, useCallback, useEffect } from "react";
import { useAuthentication } from "./useAuthentication";
import { useDispatch, useSelector } from 'react-redux'
import { setFormIndex, toggleDialogOpen } from "../features/auth/authSlice";
import { AuthForms } from "utilities/constants";

export const useAuthWallFunction = (customFunction) => {
  const { isAuthenticated, user } = useAuthentication();
  const dispatch = useDispatch();
  const open = useSelector((state) => state.auth.dialogOpen);
  const [functionTriggered, setFunctionTriggered] = useState(false);

  //we want our custom function to fire on authentication successful
  useEffect(() => {
    console.log("awd")

    console.log(isAuthenticated);
    console.log(open);
    console.log(isAuthenticated);

    if (functionTriggered && open && isAuthenticated) {
      customFunction();
      setFunctionTriggered(false);
    }

    if (functionTriggered && !open) {
      setFunctionTriggered(false);
    }

    return function () {

    };
  }, [isAuthenticated, customFunction, functionTriggered, open]);


  const privateFunction = useCallback(() => {


    if (isAuthenticated) {
      customFunction();
    }
    else {
      setFunctionTriggered(true);
      dispatch(setFormIndex(AuthForms.LOGIN))
      dispatch(toggleDialogOpen())
    }
  },
    [customFunction, isAuthenticated, dispatch, setFunctionTriggered])


  return privateFunction;
};
